.react-time-picker__inputGroup__input {
	color: white;
}

.react-time-picker__clear-button {
	color: white !important;
	stroke: white !important;
}

.react-time-picker__clear-button__icon {
	color: white !important;
	stroke: white !important;
}

.react-time-picker__clock-button {
	color: white !important;
	stroke: white !important;
}

.react-time-picker__clock-button__icon {
	color: white !important;
	stroke: white !important;
}

.react-time-picker__inputGroup__amPm {
	& option {
		background-color: #1e1f23;
	}
}
