#file-upload-wrapper {
	border: 2px dashed #8a69ff;
	cursor: pointer;
	background-image: url('./assets/audience-image.png');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.customOverlay {
	position: absolute;
	display: flex;
	inset: 0px;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 500;
	justify-content: center;
	overflow-x: hidden;
	pointer-events: none;
}

.customModal {
	pointer-events: visible;
	margin: auto;
	top: 50%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	border-radius: 4px;
	outline: none;
	z-index: 500;
	max-height: 90vh;
}
