//
// Item Hover Custom
//


.item-border-hover {
    border: 1px solid transparent;
    border-radius: 6px;

    &:hover {
        border: 1px solid $gray-200;
        cursor: pointer;
        transition: $transition-link;

        .label {
            background-color: $primary !important;
            transition: $transition-link;
            color: $white;
        }
    }

    .label {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        color: $gray-500;
    }
}
