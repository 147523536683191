@keyframes unlock-circle {
	0% {
		bottom: 50px;
	}
	100% {
		bottom: 66px;
	}
}

@keyframes unlock-box {
	0% {
		bottom: 76px;
	}
	100% {
		bottom: 93px;
	}
}

@keyframes lock-circle {
	0% {
		bottom: 66px;
	}
	100% {
		bottom: 50px;
	}
}

@keyframes lock-box {
	0% {
		bottom: 93px;
	}
	100% {
		bottom: 76px;
	}
}

.wrapper {
	height: 80px;
	width: 80px;
	transform: translateY(-35px);
	margin-left: 10px;
}

.base {
	background-color: #ffc83d;
	width: calc(200px / 3);
	height: calc(170px / 3);
	border-radius: calc(30px / 3);
	margin: 0 auto;
	position: relative;
	top: calc(100px / 3);
	z-index: 100;
}

.base-bottom {
	background-color: #ffc83d;
	width: calc(200px / 3);
	height: calc(85px / 3);
	border-radius: 0 0 calc(30px / 3) calc(30px / 3);
	top: calc(85px / 3);
	position: relative;
	opacity: 1;
}

.lock-circle {
	animation-duration: 1s;
	height: calc(180px / 3);
	width: calc(110px / 3);
	border-radius: calc(45px / 3);
	z-index: 10;
	background-color: #cccccc;
	position: relative;
	margin: 0 auto;
	bottom: calc(150px / 3);
	&.unlock {
		animation-name: unlock-circle;
		animation-fill-mode: forwards;
	}
	&.lock {
		animation-name: lock-circle;
		animation-fill-mode: forwards;
	}
}

.lock-circle-inside {
	height: calc(180px / 3);
	width: calc(50px / 3);
	border-radius: calc(30px / 3);
	z-index: 20;
	background-color: white;
	position: relative;
	margin: 0 auto;
	top: calc(25px / 3);
}

.lock-box {
	animation-duration: 1s;
	position: relative;
	height: calc(50px / 3);
	width: calc(50px / 3);
	background-color: white;
	bottom: 76px;
	left: 42px;
	z-index: 80;
	&.unlock {
		animation-name: unlock-box;
		animation-fill-mode: forwards;
	}
	&.lock {
		animation-name: lock-box;
		animation-fill-mode: forwards;
	}
}

.lock-inside-top {
	width: calc(50px / 3);
	height: calc(50px / 3);
	border-radius: calc(50px / 3);
	background-color: black;
	z-index: 300;
	position: relative;
	bottom: calc(45px / 3);
	left: calc(75px / 3);
}

.lock-inside-bottom {
	width: calc(30px / 3);
	height: calc(80px / 3);
	background-color: black;
	z-index: 300;
	position: relative;
	bottom: calc(85px / 3);
	left: calc(85px / 3);
}
