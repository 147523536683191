$duration: 0.5s;

.menu {
	margin: 0;
	display: flex;
	/* Works well with 100% width  */
	width: 100%;
	/*position: relative;*/
	align-items: center;
	justify-content: space-between;
	padding: 0.3em;
}

.menu__item {
	all: unset;
	z-index: 100;
	display: flex;
	cursor: pointer;
	position: relative;
	border-radius: 50%;
	align-items: center;
	will-change: transform;
	justify-content: center;
	transition: transform var(--timeOut, $duration);
	width: 40px;
	height: 40px;
}

.menu__item::before {
	content: '';
	z-index: -1;
	border-radius: 50%;
	position: absolute;
	transform: scale(0);
	// // transition: background-color $duration,
	// 	transform $duration;
}

.menu__item.active::before {
	transform: scale(1);
	// background-color: var(--bgColorItem);
}

.menu__item.active {
	transform: translate3d(0, -0.5em, 0);
	// background-color: #8a69ff;
}

.icon {
	stroke: white;
	fill: transparent;
	stroke-width: 1pt;
	stroke-miterlimit: 10;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-dasharray: 400;
}

.menu__item.active .icon {
	animation: strok 1.5s reverse;
}

@keyframes strok {
	100% {
		stroke-dashoffset: 400;
	}
}

.menu__border {
	left: 0;
	bottom: 95%;
	width: 10.9em;
	height: 2.4em;
	position: absolute;
	clip-path: url(#menu);
	will-change: transform;
	transition: transform var(--timeOut, $duration);
}

.svg-container {
	width: 0;
	height: 0;
}
