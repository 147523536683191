.nav-circle-container {
	width: calc(100% / 6); // for 6 circles = 100 / 6
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 50;
}

.nav-circle {
	background-color: black;
	border: 5px solid white;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	z-index: 50;
	display: flex;
	justify-content: center;
	align-items: center;
}

#create-experience-navbar {
	display: flex;
	justify-content: space-between;
	width: 100%;
	&-container {
		position: relative;
		display: flex;
	}
}
.create-experience {
	&-line {
		height: 50%;
		width: 50%;
		z-index: 2;
		transform: translateX(50%);
		&:last-child {
			transform: translateX(-50%);
		}
	}
}

#create-experience-line-container {
	width: 100%;
	height: 10px;
	position: absolute;
	align-self: center;
	display: flex;
	z-index: 1;
}

.navbar-label-container-bar {
	color: white;
	display: flex;
	justify-content: space-between;
}
.navbar-label-container {
	display: flex;
	width: 16.34%;
	justify-content: center;
	& p {
		margin-bottom: 10px;
	}
}

.navbar-label {
	display: block;
	font-size: 1rem !important;
}
