div.ql-editor {
	background-color: white;
	color: black;
	overflow-y: auto;
}
.ql-container.ql-snow {
	background-color: white;
	min-height: 150px;
}

.quill {
	background-color: white;
	border-radius: 5px;
}

.shrink-content {
	& p {
		margin-bottom: 0;
	}
}
