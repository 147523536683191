//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary: #00a3ff;
$primary-active: #008bd9;
$primary-light: if(isDarkMode(), #212e48, #f1faff);
$primary-inverse: #ffffff;

// Success
$success: #50cd89;
$success-active: #47be7d;
$success-light: if(isDarkMode(), #1c3238, #e8fff3);
$success-inverse: #ffffff;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: if(isDarkMode(), #2f264f, #f8f5ff);
$info-inverse: #ffffff;

// Danger
$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: if(isDarkMode(), #3a2434, #fff5f8);
$danger-inverse: #ffffff;

// Warning
$warning: #ffc700;
$warning-active: #f1bc00;
$warning-light: if(isDarkMode(), #392f28, #fff8dd);
$warning-inverse: #ffffff;

// Card Box Shadow
$card-box-shadow: if(
	isDarkSkin(),
	none,
	0px 0px 20px 0px rgba(76, 87, 125, 0.02)
);
