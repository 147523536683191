.alertOverlay {
	position: absolute;
	display: flex;
	inset: 0px;
	background-color: rgba(0, 0, 0, 0.6);
	color: white;
	z-index: 999;
	justify-content: center;
	overflow-x: hidden;
}

.alertModal {
	pointer-events: visible;
	margin: auto;
	top: 50%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	border-radius: 4px;
	outline: none;
	z-index: 500;
}
