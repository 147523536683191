//
// Aside Default
//

// General mode(all devices)
.aside.aside-default {
	display: flex;
	flex-direction: column;
	box-shadow: get($aside-config, default, box-shadow);
	background-color: get($aside-config, general, bg-color);
}

// Desktop mode
@include media-breakpoint-up(lg) {
	.aside.aside-default {
		padding: 0 get($page-spacing, desktop);
		width: get($aside-config, default, width);
		transition: width get($aside-config, general, transition-speed) ease;

		// Logo
		.aside-logo {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.logo-default {
				display: inline-block;
			}

			.logo-minimize {
				display: none;
			}
		}

		// Aside menu
		.aside-menu {
			width: get($aside-config, default, menu-width); 
		}

		// Fixed aside mode
		.aside-fixed & {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: get($aside-config, general, z-index);
			overflow: hidden;
		}

		// Static aside mode
		.aside-static & {
			position: relative;
			z-index: 1;
		}

		// Minimize aside mode
		[data-kt-aside-minimize="on"] & {
			width: get($aside-config, default, minimized-width);
			transition: width get($aside-config, general, transition-speed) ease;

			// Logo
			.aside-logo {
				.logo-default {
					display: inline-block;
				}
	
				.logo-minimize {
					display: none;
				}
			}

			// Hover mode
			&.aside-hoverable:hover {
				transition: width get($aside-config, general, transition-speed) ease;
				width: get($aside-config, default, width);
				box-shadow: get($aside-config, default, minimized-hover-box-shadow);
			}

			// Not hovered mode
			&:not(.aside-hoverable),
			&:not(:hover) {
				// Logo
				.aside-logo {
					.logo-default {
						display: none;
					}
		
					.logo-minimize {
						display: inline-block;
					}
				}				

				// Menu
				.aside-menu {
					.menu-content,
					.menu-title {
						opacity: 0;
						transition: opacity get($aside-config, general, transition-speed) ease;
					}

					.menu-item.show > .menu-sub {
						height: 0;
						overflow: hidden;
						transition: height get($aside-config, general, transition-speed) ease;
					}
				}
			}
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.aside.aside-default {
		display: none;
		padding: 0 get($page-spacing, tablet-and-mobile);

		// Logo
		.aside-logo {
			display: none;
		}
	}
}
