//Custom Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import './styles/craft-ui/sass/style.scss';

//Custom color vars
$off-white: #f5f5f8;
$white: #ffffff;
$black: #000000;
$gray: #c4c4c4;
$gray-dark: #696969;
$gray-alt: #383a3a;
$dark: #1e1f23;
$dark-light: #2a2b2f;
$dark-alt: #151718;
$primary: #005eff;
$green: #0acf83;
$red: #fd4169;
$turquoise: #22bbee;
$pink: #ca4ffd;
$orange: #ef8961;
$indigo: #2b1e6f;
$indigo-light: #8a69ff;
$purple: #8a69ff;
$dark-turquoise: #2e5e6e;

//Set custom color object
$custom-colors: (
	'off-white': $off-white,
	'white': $white,
	'black': $black,
	'gray': $gray,
	'gray-dark': $gray-dark,
	'dark': $dark,
	'dark-alt': $dark-alt,
	'primary': $primary,
	'green': $green,
	'red': $red,
	'turquoise': $turquoise,
	'pink': $pink,
	'orange': $orange,
	'indigo': $indigo,
	'indigo-light': $indigo-light,
	'purple': $purple,
	'dark-turquoise': $dark-turquoise,
	'gray-alt': $gray-alt,
);

$font-family-sans-serif: 
	//Default Font (default user interface font)
	'Montserrat',
	// Cross-platform generic font family
	system-ui,
	// Safari for macOS and iOS (San Francisco)
	-apple-system,
	// Chrome < 56 for macOS (San Francisco)
	BlinkMacSystemFont,
	// Windows
	'Segoe UI',
	// Android
	Roboto,
	// Basic web fallback
	Arial,
	// Linux
	'Noto Sans',
	// Sans serif fallback
	sans-serif,
	// Emoji fonts
	'Apple Color Emoji',
	'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;

$theme-colors: map-merge(
	$theme-colors,
	(
		'off-white': $off-white,
		'white': $white,
		'black': $black,
		'gray': $gray,
		'gray-dark': $gray-dark,
		'dark': $dark,
		'primary': $primary,
		'green': $green,
		'red': $red,
		'turquoise': $turquoise,
		'dark-turquoise': $dark-turquoise,
		'pink': $pink,
		'orange': $orange,
		'indigo': $indigo,
		'indigo-light': $indigo-light,
		'purple': $purple,
	)
);

$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop(
	$theme-colors,
	to-rgb,
	'$value'
);
$utilities-colors: map-merge(
	$utilities-colors,
	$theme-colors-rgb
);
$utilities-text-colors: map-loop(
	$utilities-colors,
	rgba-css-var,
	'$key',
	'text'
);
$utilities-bg-colors: map-loop(
	$utilities-colors,
	rgba-css-var,
	'$key',
	'bg'
);

$aspect-ratios: (
	'1x1': 100%,
	'4x3': calc(3 / 4 * 100%),
	'16x9': calc(9 / 16 * 100%),
	'21x9': calc(9 / 21 * 100%),
);

$aspect-ratios: map-merge(
	$aspect-ratios,
	(
		'30x7': calc(7 / 30 * 100%),
	)
);

@import 'bootstrap';

//Global app settings
html,
body,
#root,
#app,
#home-wrapper {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
}

.gradient-button {
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
	opacity: 0.93;
	background-image: linear-gradient(
		90deg,
		rgba(202, 79, 253, 1) 0%,
		rgba(138, 105, 255, 1) 60%,
		rgba(34, 187, 238, 1) 100%
	);
	&:hover {
		opacity: 1;
	}
}

#left-nav-main-container {
	display: flex;
	width: 100%;
	overflow-x: hidden;
	position: relative;
	@media screen and (min-width: 992px) {
		padding-left: 100px;
		&.secondaryAside {
			padding-left: 340px;
		}
	}
}

.email-template-image {
	background-size: contain !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
}

// will be useful for controlling the color of icons
// To actually change the color of them you need the property
// color: "color goes here", so this code just extends that

.color {
	&-off-white {
		color: $off-white;
	}
	&-white {
		color: $white;
	}
	&-black {
		color: $black;
	}
	&-gray {
		color: $gray;
	}
	&-dark {
		color: $dark;
	}
	&-primary {
		color: $primary;
	}
	&-green {
		color: $green;
	}
	&-red {
		color: $red;
	}
	&-turquoise {
		color: $turquoise;
	}
	&-pink {
		color: $pink;
	}
	&-orange {
		color: $orange;
	}
	&-indigo {
		color: $indigo;
	}
	&-purple {
		color: $purple;
	}
}

.noselect {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.nodrag {
	// user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.btn-outline-turquoise:hover {
	color: white !important;
}

.btn-outline-red:active:focus {
	color: #000000 !important;
	&i {
		color: #000000 !important;
		background-color: #000000 !important;
	}
}

.shrink-content {
	flex: 1 1 auto;
	overflow-y: auto;
	height: 0px; /* This allows for infinite shrinkage of content w/ scroll*/
}

.scrollbar::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: white;
}

.scrollbar::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.scrollbar::-webkit-scrollbar-track-piece {
	height: 50%;
	width: 50%;
}

.scrollbar::-webkit-scrollbar-thumb {
	background-color: $dark-light;
	border-radius: 5px;
}

@keyframes blink-yellow {
	0% {
		border: 2px solid black;
	}

	80% {
		border: 2px solid black;
	}

	90% {
		border: 2px solid yellow;
	}
	100% {
		border: 2px solid black;
	}
}

@keyframes blink-green {
	0% {
		border: 2px solid black;
	}

	80% {
		border: 2px solid black;
	}

	90% {
		border: 2px solid $green;
	}
	100% {
		border: 2px solid black;
	}
}

.react-calendar-highlight-test-reminder {
	border: 2px solid $orange !important;
	border-radius: 5px;
}

.react-calendar-highlight-winning-launch {
	border: 2px solid $pink !important;
	border-radius: 5px;
}

.testSendButton {
	.testSendIcon {
		color: $green;
	}
	&:hover {
		.testSendIcon {
			color: white;
		}
	}
}
.backIconButton {
	.backIcon {
		color: white;
	}
	&:hover {
		.backIcon {
			color: black;
		}
	}
}

// Custom Sweet Alert styles
.swal-modal {
	.swal-title {
		color: white;
	}
	.swal-text {
		color: white;
	}
	.swal-footer {
		text-align: center;
		justify-content: center;
		color: white;
	}
}
