.main-navbar {
	&-item {
		width: 42px; // Life the universe and everything
		height: 42px;
		cursor: pointer;
		color: white;
		border-radius: 5px;
		&:hover:not(.main-navbar-item-active) {
			border: 2px solid #8a69ff;
		}
		&-active {
			border: 2px solid #8a69ff;
		}
	}
}

.main-navbar-item {
	& i {
		font-size: x-large;
	}
}
