@import '../../App.scss';

.input-range {
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	&-slider {
		cursor: pointer;
		height: 20px;
		width: 20px;
		background-color: $purple;
		border-radius: 100%;
		::hover {
			opacity: 0.7;
		}
	}

	&-value-label {
		color: $white;
		position: absolute;
		top: -23px;
		position: absolute;
	}

	&-slider-container {
		// position: relative;
		top: -50%;
		bottom: -50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-active-track {
		background-color: $purple;
		height: 10px;
		position: absolute;
		border: 0px solid transparent;
		border-radius: 25px 0px 0px 25px;
	}

	&-track {
		width: 100%;
		height: 50%;
		background-color: $indigo;
		z-index: 100;
		border-radius: 0px 25px 25px 0px;
	}

	&-max-label {
		display: none;
	}
	&-min-label {
		display: none;
	}
}
