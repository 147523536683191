@keyframes moving-gradient {
	0% {
		background-position: 0px 0;
	}
	100% {
		background-position: 1000px 0;
	}
}

.customTd {
	height: 50px;
	vertical-align: middle;
	padding: 8px;
	span {
		display: block;
		border-radius: 15px;

		height: 15px;
		background: linear-gradient(
			to right,
			#ca4ffd 0%,
			#8a69ff 33%,
			#22bbee 66%,
			#ca4ffd 100%
		);
		background-size: 1000px;
		animation-name: moving-gradient;
		animation-duration: 2s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-fill-mode: forwards;
	}
}
